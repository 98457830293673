<template>
    <BT-Blades
        :anchorBlades="[{ bladeName: 'supply-pointers' }]">
        <template v-slot="bladesData">
            <Supply-Pointers-Blade :bladesData="bladesData" />
            <Supply-Pointer-Blade :bladesData="bladesData" />
        </template>
    </BT-Blades>
</template>

<script>
export default {
    name: 'Supply-Pointers-Hub',
    components: {
        SupplyPointerBlade: () => import('~home/supply-pointers/Supply-Pointer-Blade.vue'),
        SupplyPointersBlade: () => import('~home/supply-pointers/Supply-Pointers-Blade.vue')
    }
}
</script>